$screen-sm-max: 768px;

$font-family: "Open Sans", sans-serif;

$bg-gray-50:  rgba(249, 250, 251);	
$bg-gray-100: rgba(243, 244, 246);	
$bg-gray-200: rgba(229, 231, 235);	
$bg-gray-300: rgba(209, 213, 219);	
$bg-gray-400: rgba(156, 163, 175);	
$bg-gray-500: rgba(107, 114, 128);	
$bg-gray-600: rgba(75, 85, 99);	
$bg-gray-700: rgba(55, 65, 81);	
$bg-gray-800: rgba(31, 41, 55);	
$bg-gray-900: rgba(17, 24, 39);

$bg-indigo-50: rgb(238 242 255);	
$bg-indigo-100: rgb(224 231 255);	
$bg-indigo-200: rgb(199 210 254);	
$bg-indigo-300: rgb(165 180 252);	
$bg-indigo-400: rgb(129 140 248);	
$bg-indigo-500: rgb(99 102 241);	
$bg-indigo-600: rgb(79 70 229);	
$bg-indigo-700: rgb(67 56 202);	
$bg-indigo-800: rgb(55 48 163);	
$bg-indigo-900: rgb(49 46 129);

$primary-color: $bg-indigo-700; /* <=> #3943AE */
$secondary-color: #E33D32;
$gray-bg: rgb(242, 242, 250);
$action-color: rgb(50, 111, 254);
$border-color: #dee2e6;

$theme-colors: (
  "primary": $primary-color,
  "danger": #ff4136
);

.bg-red-500 {
  background-color: rgb(239 68 68);
}
.bg-green-500 {
  background-color: rgb(34 197 94);
}
.bg-gray-500 {
  background-color: rgb(107 114 128);
}
.bg-yellow-500 {
  background-color: rgb(234 179 8);
}
.bg-slate-500 {
  background-color: 'white';
}

.text-red-500 {
  color: rgb(239 68 68);
}
.text-green-500 {
  color: rgb(34 197 94);
}
.text-gray-500 {
  color: rgb(107 114 128);
}
.text-yellow-500 {
  color: rgb(234 179 8);
}
.text-slate-500 {
  color: 'white';
}
