@tailwind base;
@tailwind components;
@tailwind utilities;

@import "trix/dist/trix";
@import "../stylesheets/actiontext.scss";

@import "../stylesheets/variables";
@import "../stylesheets/layout";
@import "../stylesheets/typography";

@import "../stylesheets/components/navbar";
@import "../stylesheets/components/login";
@import "../stylesheets/components/page";
@import "../stylesheets/components/table";
@import "../stylesheets/components/form";
@import "../stylesheets/components/flash";
@import "../stylesheets/components/list";
@import "../stylesheets/components/sidebar";
@import "../stylesheets/components/comment";
@import "../stylesheets/components/grid";
@import "../stylesheets/components/phase";
@import "../stylesheets/components/calendar";
@import "../stylesheets/components/effects";

@import "../stylesheets/components/planning";
