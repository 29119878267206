.flash {
  position: absolute;
  top: 36px;
  width: 50%;
  left: 25%;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-size: 14px;
  z-index: 999;
  font-size: inherit;
}

.flash--no-absolute {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  padding: 3px 6px;
  font-size: 0.95em;
}

.flash--notice {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.flash--error,
.flash--alert {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.flash--blue {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.flash--warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.flash-container {
  position: relative;
}

.flash__button-close {
  position: absolute;
  top: calc(50% - 6px);
  right: 0px;
  background-image: url('~images/close.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-color: transparent;
  width: 12px;
  height: 12px;
  border: none;
  cursor: pointer;
}