.list__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #dedede;
}
.list__item:last-child {
  border-bottom: none;
}

.list__item__main {
  font-weight: bold;
  width: calc(20% - 12px / 3)
}
.list__item__secondary {
  text-align: left;
  font-weight: normal;
  color: gray;
  width: calc(60% - 12px / 3)
  }

.list__item__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a, input {
    margin: 0 8px;
  }
}