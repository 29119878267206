.login {
  width: 100%;
}
.login-title {
  text-align: center;
  padding: 24px 0;
}
.login-bottom-links {
  text-align: center;
  padding: 12px;
}