.sidebar {
  background: white;
  min-width: 200px;
  max-width: 30%;
  padding-top: 12px;
}

.sidebar__section {
  color: grey;
  padding: 12px;
}

.sidebar__title {
  text-transform: uppercase;
  font-weight: bold;
}

.sidebar__item {
  padding: 8px 0 0 8px;
  a {
    color: inherit;
    text-decoration: inherit;
  }
  a.active {
    color: $primary-color;
    font-weight: bold;
  }
}