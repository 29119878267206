
/*
When setting the primary font stack, apply it to the Pure grid units along
with `html`, `button`, `input`, `select`, and `textarea`. Pure Grids use
specific font stacks to ensure the greatest OS/browser compatibility.
*/
html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  /* Set your content font stack here: */
  font-family: $font-family;
}

.btn {
  border-radius: 8px;
  text-transform: uppercase;
  background-color: $action-color;
  color: white;
  padding: 8px 12px;
  text-decoration: none;
}

.btn--small {
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 90%;
}

.btn--extra-small {
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 75%;
}

.btn--outlined {
  background-color: white;
  color: $action-color;
  border-color: $action-color;
}

.button_to {
  input {
    padding: 0;
    background: none;
    border: none;
    background-color: white;
    color: $primary-color;
    text-decoration: underline;
    font-size: 1em;
    cursor: pointer;
  }
}