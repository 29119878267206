.phase-table {
  width: 100%;
  text-align: center;

  td:first-child {
    text-align: left;
  }
}

.phase-row--disabled {
  opacity: 0.3;
}

.phase-kind-text {
  min-width: 150px;
}

.phase-year {
  min-width: 50px;
  max-width: 60px;
}

.phase-date {
  min-width: 105px;
  max-width: 95px;
}

.phase-state {
  padding: 0;
  select {
    width: 100%;
    height: 100%;
    border: 0px;
    border-color: transparent;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.phase-state--todo {
  background-color: #f5f5f5;
}
.phase-state--doing {
  background-color: yellow
}
.phase-state--blocked {
  background-color:rgb(255, 74, 74);
  * {
    color: white;
  }
}
.phase-state--done {
  background-color: lawngreen;
}
.phase-state--not_applicable {
  border: 1px dashed $border-color;
  background-color: #b6b6b6;
  opacity: 0.5;
}
