.page {
  width: 100%;
}
.page-header {
  width: 100%;
  margin: 0;
  display: inline-flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: rgb(254, 254, 255);
  padding: 4px 16px;
  min-height: 48px;
  -webkit-box-align: center;
  align-items: center;
}

.page-header__title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0.0125rem;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
}

.page-header__badge {
  background-color: $bg-indigo-200;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0px 8px;
  margin: 0 0 0 12px;
  border-radius: 4px;
  max-height: 28px;
}

.page-header__speparator {
  margin: 0 6px;
}

.page-body {
  margin: 16px;
  background: white;
  border-radius: 16px;
}

.page-body--small {
  min-width: 0;
  max-width: 60%;
  margin: 16px auto;
}

.page-body--flex {
  display: flex;
  flex-wrap: wrap;
}

.page-body--under-tabs {
  border-top-left-radius: 0px;
  margin: 8px 16px;
}

.page-body__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  border-bottom: 1px solid #ddd;
  min-height: 36px;
}

.page-body--with-padding {
  padding: 1em;
}

@media screen and (max-width: $screen-sm-max) {
  .page-body, .page-body--under-tabs {
    margin: 0;
    border-radius: 0;
  }
}

/* tabs */

.page-tabs {
  position: relative;
}

.page-tabs-list {
  margin-top: 32px;
  padding: 0 16px;
  display: flex;
}

.page-tabs-item {
  list-style: none;
  margin-right: 8px;
  border-bottom: 1px solid $border-color;
}

.page-tabs-item a {
  letter-spacing: 0.06em;
  padding: 12px 16px;
  text-decoration: underline;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 110%;
  background-color: $bg-gray-300;
  &.active {
    background: white;
    font-size: 120%;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid white;
  }
}

.page-tabs-actions {
  position: absolute;
  top: 0;
  right: 28px;
  a { background: transparent; }
}