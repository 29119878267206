body {
  font-size: .75rem;
  background-color: $bg-gray-200;
}

body,
html,
section {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

header,
nav,
footer,
section,
article,
div {
  box-sizing: border-box;
}
