.form--horizontal {
  width: 100%;
}

.form--login {
  width: 25%;
  padding: 24px;
  margin: 0 auto;
  background: white;
  border-radius: 8px;
}

.form__fieldset:nth-of-type(3) {
  margin-right: 16px;
}
.form__fieldset {
  width: calc(33% - (4 * 16px - 10px));
  margin-top: 16px;
  margin-left: 16px;
  padding: 16px;
  min-height: 100px;
  border: 1px solid $border-color;
  border-radius: 4px;
  legend{
    font-size: 1.5em;
    font-weight: bold;
    font-family: $font-family;
    letter-spacing: 0.06em;
    color: $primary-color;
    overflow-wrap: break-word;
  }
}

.form__field {
  padding: 4px;
  input:not([type='checkbox']), select, textarea {
    padding: .5em .6em;
    display: inline-block;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 3px #ddd;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &.form-error {
      border-color: red;
    }
  }
  input[type='checkbox'] {
    width: auto;
  }
  label.form-error {
    color: red;
  }
}

.form--horizontal {
  .form__field {
    display: flex;
    width: 100%;
    align-items: center;
    label {
      text-align: right;
      width: 30%;
      margin: 0 1em 0 0;
    }
    input:not([type='checkbox']), select, textarea {
      width: 50%;
    }
  }
  .form__field--left {
    label {
      text-align: left;
    }
  }
}
.form--vertical {
  .form__field {
    text-align: left;
    display: block;
    margin-bottom: 8px;
    input {
      display: inline-block;
      border: 1px solid $border-color;
      -webkit-box-shadow: inset 0 1px 3px #ddd;
      box-shadow: inset 0 1px 3px #ddd;
      border-radius: 4px;
      vertical-align: middle;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    input:not([type='checkbox']), select, textarea {
      width: 100%;
    }
  }
}

.form__field--date {
  select {
    width: 20%
  }
}

.form__field--border-bottom {
  border-bottom: 1px solid $border-color;
}

.form__actions {
  border-top: 1px solid $border-color;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.form__actions--with-margin-top {
  margin-top: 16px;
}

.form--login {
  .form__actions {
    border: none;
    margin: 0;
    padding: 0;
  }
}

.form__submit {
  border: none;
  border-radius: 8px;
  text-transform: uppercase;
  background-color: $action-color;
  color: white;
  padding: 8px 12px;
  text-decoration: none;
  cursor: pointer;
}

.form__submit--fullwidth {
  width: 100%;
  margin: 0 4px;
}

.form__nested-fields__wrapper {
  width: 100%;
}  
.form__nested-fields {
  width: 100%;
}

.form__nested-fields__links {
  width: 100%;
  padding-bottom: 4px;
  padding-left: calc(30% + 1.4em);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}



.grid__form {
  width:100%;
  label {
    font-size: 0.9em;
  }
  th {
    text-align: left;
    font-weight: normal;
    width: 15%;
  }
  th.grid__form__comment {
    min-width: 55%
  }
  td input, td select {
    display: inline-block;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 3px #ddd;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  td input[type=number] {
    width: 50px;
  }
  td input[type=date] {
    width: 94px;
  }
  td input[type=date]::-webkit-calendar-picker-indicator {
    margin-left: -30px;
  }
  td select {
    width: auto;
  }
  td input[type=text] {
    width: auto;
  }
}

.grid__form--todo {
  border: 1px dashed $border-color;
  background-color: #f5f5f5;
}
.grid__form--doing {
  background-color: yellow
}
.grid__form--blocked {
  background-color:rgb(255, 74, 74);
  color: white;
}
.grid__form--done {
  background-color: lawngreen;
}
.grid__form--not_applicable {
  border: 1px dashed $border-color;
  background-color: #f5f5f5;
  opacity: 0.5;
}

.form__simple-actions {
  text-align: right;
}