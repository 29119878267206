.comment {
  background-color: $bg-indigo-50;
  margin: 6px 4px;
  padding: 2px 4px;
  position: relative;
}

.comment__authored {
  color: dimgray;
  font-size: 75%;
  position: absolute;
  right: 10px;
  bottom: 4px;
}