.grid-fieldset {
  /*background: lavenderblush;*/
  height: 100%;
  margin: 0;
  border-radius: 6px;
  /* use Chrome defaults removed by Tailwind reset */
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  legend{
    margin-left: 12px;
    font-size: 1.5em;
    font-weight: bold;
    font-family: $font-family;
    letter-spacing: 0.06em;
    color: $primary-color;
    overflow-wrap: break-word;
  }
}

.form-grid-container-1 {
  display: grid;
  grid-template-columns: 1fr;
}
.form-grid-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.form-grid-container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.form-grid-container-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: $screen-sm-max) {
  .form-grid-container-2 { grid-template-columns: 1fr; }
}

.form-grid-item {
  padding: 0.5rem;
  label {
    display: block;
    width: 100%;
    // font-weight: bold;
    padding: .2rem .3rem;
  }
  input, select, textarea {
    padding: .2rem .3rem;
    display: inline-block;
    border: 1px solid $border-color;
    -webkit-box-shadow: inset 0 1px 3px #ddd;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
  }
  input[type='checkbox'] {
    width: auto;
  }
}

::-webkit-datetime-edit { padding: 0em; }
// ::-webkit-datetime-edit-fields-wrapper {  }
::-webkit-datetime-edit-text {
  margin: 0px;
  padding: 0px;
}
// ::-webkit-datetime-edit-month-field { }
// ::-webkit-datetime-edit-day-field {  }
// ::-webkit-datetime-edit-year-field {  }
// ::-webkit-inner-spin-button { }
::-webkit-calendar-picker-indicator { 
  margin: 0px;
  padding: 0px;
}

.form-grid-item--horizontal {
  display: flex;
  width: 100%;
  align-items: center;
  label {
    text-align: left;
    // margin: 0 1em 0 0;
  }
  input, select, textarea {
  }
}

.form-table {
  width: 100%;
  text-align: center;
  td {
    padding: 0.5em;
  }
  td:first-child {
    text-align: left;
  }
}

.housing-lot {
  background: $bg-gray-100;
  margin: 8px 4px;
  position: relative;
}

.housing-delete {
  position: absolute;
  top: 0;
  right: 0;
}

.housing-add {
  text-align: right;
  padding: 4px;
}