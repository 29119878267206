
/* **************** */

.multiselect {
  width: 100%;
  position: relative;

  .selectBox {
    position: relative;
  }

  .selectBox select {
    width: 100%;
    font-weight: bold;
  }

  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .checkboxes {
    background: white;
    position: absolute;
    display: none;
    border: 1px #dadada solid;
    text-align: left;
    font-weight: normal;
    max-height: 80vh;
    overflow: auto;
  }

  .checkboxes label {
    display: block;
    padding: 4px 8px;
  }

  .checkboxes label:hover {
    background-color: $bg-indigo-200;
  }

  .checkboxes input[type=checkbox] {
    width: 16px;
    margin: 4px;
    vertical-align: middle;
  }
}
