.navbar {
  background-color: $primary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar__logo {
  display: block;
  width: auto;
  padding: 0 16px;
  img {
    height: 24px;
  }
  background-color: transparent;
}

.navbar__menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar__item {
  display: block;
  text-align: center;
  padding: 16px 8px;
  text-decoration: none;
  font-size: 14px;
  &:last-child {
    padding-right: 16px;
  }
  background-color: transparent;
  color: white;
  opacity: 0.7;
}

.navbar__item:hover,
.navbar__item.active {
  color: white;
  opacity: 1;
}

.navbar__burger {
  padding: 16px;
  display: none;
}

@media screen and (max-width: $screen-sm-max) {

  .navbar {
    flex-wrap: wrap;
    position: relative;
  }

  .navbar__logo {
    width: 50%;
    padding: 8px;
  }

  .navbar__burger {
    display: block;
  }

  .navbar__nav {
    width: 100%;
    display: none;
    min-width: 300px;
    justify-content: center;
    position: absolute;
    top: 80px;
    /*background: white;*/
    z-index: 1;
  }
  
  .navbar__menu {
    flex-direction: column;
    width: 100%;
  }

  .navbar__nav--opened {
    display: flex;
  }
}
